import '../css/css.css'

export function Accueil() {
    document.querySelector("title").innerHTML = "Accueil"

    return(
        <div className="accueil">
            <h1>Accueil</h1>
            <p>Bienvenue sur notre application ludique développé par Adrien Meynard, étudiant à l'EPSI.</p><br />
            <h2>Cette application a deux buts : </h2><br />

        	<p> -le premier est de développer sa culture générale sur les 250 pays du monde <br />
            en apprenant leur capitale, leur population ou encore connaître leur drapeau. <br /></p>

            <p>  -le second, beaucoup plus ludique est de connaître l'age moyen de personnes <br />
            qui portent le prénom que vous avez renseigné dans la barre de recherche.</p>
               
            <h2>API utilisées :  </h2>

            <p ><a className="link" href="https://agify.io">api.agify.io</a></p>
            <p ><a className="link" href="https://restcountries.com">restcountries.com</a></p>

              
  
        </div>
    )
}